import React, { useState } from 'react'

import { FormGroup, Input } from '@te-digi/styleguide'

const InputExample = () => {
  const [value, setValue] = useState('')

  return (
    <FormGroup noMargin>
      <Input
        help="Help"
        label="Label"
        onChange={event => setValue(event.target.value)}
        onBlur={val => console.log('Input onBlur', val)}
        required
        subLabel="Sub Label"
        value={value}
      />
    </FormGroup>
  )
}

export { InputExample }
