import React, { useState } from 'react'

import { FormGroup, Input } from '@te-digi/styleguide'

const InputEmailExample = () => {
  const [value1, setValue1] = useState('')
  const [value2, setValue2] = useState('')

  return (
    <>
      <FormGroup>
        <Input
          label="This input has type=email. Try typing: test@£"
          onChange={event => setValue1(event.target.value)}
          value={value1}
          type="email"
        />
      </FormGroup>
      <FormGroup noMargin>
        <Input
          label="This input has inputmode=email. Try typing: test@£"
          onChange={event => setValue2(event.target.value)}
          value={value2}
          inputMode="email"
        />
      </FormGroup>
    </>
  )
}

export { InputEmailExample }
