import React, { useState } from 'react'

import { FormGroup, Input } from '@te-digi/styleguide'

const InputClearExample = () => {
  const [value1, setValue1] = useState('Lorem ipsum dolor sit amet')

  return (
    <FormGroup noMargin>
      <Input
        clearable
        label="Label"
        onChange={event => setValue1(event.target.value)}
        value={value1}
      />
    </FormGroup>
  )
}

export { InputClearExample }
