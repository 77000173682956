import React from 'react'

import {
  Button,
  FormGroup,
  Input,
  InputGroupText,
  List,
  ListItem,
  Paragraph,
  SearchIcon,
  Strong
} from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Link } from '../../components/Link'
import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { FormLayoutWrapper } from '../../components/FormLayoutWrapper'
import { InputClearExample } from '../../examples/react/InputClearExample'
import { InputDescriptionExample } from '../../examples/react/InputDescriptionExample'
import { InputExample } from '../../examples/react/InputExample'
import { LabelWithErrorExample } from '../../examples/react/LabelWithErrorExample'
import { Section } from '../../components/Section'
import { TextFieldCharacters } from '../../components/TextFieldCharacters'
import ButtonHTMLExample from '../../examples/html/ButtonHTMLExample'
import IconHTMLExample from '../../examples/html/IconHTMLExample'
import { InputEmailExample } from '../../examples/react/InputEmailExample'
import LabelHTMLExample from '../../examples/html/LabelHTMLExample'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="Input"
    components={[
      { component: Input, restElement: true },
      { component: InputGroupText, restElement: true }
    ]}
    status={[{ type: 'accessible', version: '14.3.0' }]}
  >
    <Section>
      <Playground
        example={InputExample}
        WrapperComponent={FormLayoutWrapper}
      />
      <Playground
        format="html"
        WrapperComponent={FormLayoutWrapper}
      >
        <div className="form-group mb-0">
          <LabelHTMLExample
            htmlFor="input-html-example-1"
            required
            subLabel="Sub Label"
            subLabelId="input-html-example-1-sublabel"
          >
            Label
          </LabelHTMLExample>
          <input
            aria-describedby="input-html-example-1-sublabel"
            className="form-control"
            id="input-html-example-1"
            aria-required
            type="text"
          />
        </div>
      </Playground>
    </Section>
    <Section title="Virheviesti ja merkkilaskuri">
      <List variant="unordered">
        <ListItem>
          <Code>Input</Code>-komponentin alapuolella oleva tila on varattu
          virheviestille ja merkkilaskurille.
        </ListItem>
        <ListItem>
          Virheviestiä käyttäessä komponentin reunaviiva värjäytyy{' '}
          <Code>danger</Code> värillä.
        </ListItem>
        <ListItem>
          Komponenttiin syötettävää merkkimäärää voi rajoittaa. Ohjelmallisesti
          annettu merkkimäärän ylittävä arvo typistetään lopusta
          maksimimerkkimäärään.
        </ListItem>
        <ListItem>
          Merkkilaskuri ilmestyy näkyviin, kun tekstiä on syötetty 80 %
          merkkimäärästä. Kynnystä on mahdollista muuttaa.
        </ListItem>
        <ListItem>
          Komponentti typistää mahdollisesti liian pitkän liitetyn tekstin ja
          näyttää virheilmoituksen.
        </ListItem>
      </List>
      <Playground
        example={InputDescriptionExample}
        WrapperComponent={FormLayoutWrapper}
      />
    </Section>

    <Section title="Tyhjennys-painikkeella">
      <Playground
        example={InputClearExample}
        WrapperComponent={FormLayoutWrapper}
      />
    </Section>

    <Section title="Painike kentän loppuun">
      <Paragraph>Lomakekentän loppuun voi yhdistää painikkeen.</Paragraph>
      <Playground WrapperComponent={FormLayoutWrapper}>
        <>
          <FormGroup>
            <Input
              addonAppend={
                <Button
                  icon={<SearchIcon />}
                  variant="outline"
                >
                  Hae
                </Button>
              }
              label="Label"
            />
          </FormGroup>
          <FormGroup>
            <Input
              addonAppend={
                <Button
                  disabled
                  icon={<SearchIcon />}
                  variant="outline"
                >
                  Hae
                </Button>
              }
              label="Label"
            />
          </FormGroup>
          <FormGroup>
            <Input
              addonAppend={
                <Button
                  icon={<SearchIcon />}
                  variant="outline"
                >
                  Hae
                </Button>
              }
              error="Error"
              label="Label"
            />
          </FormGroup>
          <FormGroup noMargin>
            <Input
              addonAppend={
                <Button
                  disabled
                  icon={<SearchIcon />}
                  variant="outline"
                >
                  Hae
                </Button>
              }
              disabled
              label="Label"
            />
          </FormGroup>
        </>
      </Playground>
    </Section>

    <Section title="Etuliite: kieliversiointi">
      <Paragraph>
        Kentille voi lisätä etuliitteen <Code>addonPrepend</Code>-propilla. Tätä
        voi käyttää mm. saman tiedon eri kieliversioitujen kenttien
        merkitsemiseen.
      </Paragraph>

      <Paragraph>Huomioitavaa esimerkistä:</Paragraph>
      <List variant="unordered">
        <ListItem>
          Kenttien kieli määritellään lang-atribuutilla (esim.{' '}
          <Code>lang="fi"</Code>). Näin ruudunlukija osaa ääntää syötettävän
          tekstin oikealla ääntämyksellä. Kielen asettaminen vaikuttaa paitsi
          kentän arvoon, myös ja labeliin ja <Code>aria-describedby</Code>:llä
          liitettyyn tekstiin (tässä tapauksessa virheviestiin).
        </ListItem>

        <ListItem>
          <Code>addonPrepend</Code>illä lisättävän elementin sisällöksi annetaan
          kielen lyhenne (esim. FI) ja <Code>aria-label</Code>iksi kieli
          kyseisellä kielellä aukikirjoitettuna (esim. Suomeksi, In English).
        </ListItem>
        <ListItem>
          Kentät kytketään yhteen <Code>FormGroup</Code>illa, jossa määritellään
          kenttien yhteinen <Code>Label</Code>.
        </ListItem>
      </List>
      <Playground
        example={LabelWithErrorExample}
        WrapperComponent={FormLayoutWrapper}
      />
    </Section>

    <Section title="Takaliite: valuutta">
      <Playground WrapperComponent={FormLayoutWrapper}>
        <>
          <FormGroup>
            <Input
              addonAppend={<InputGroupText>€</InputGroupText>}
              label="Label"
            />
          </FormGroup>
          <FormGroup noMargin>
            <Input
              addonAppend={<InputGroupText>€</InputGroupText>}
              label="Label"
              error="Error"
            />
          </FormGroup>
        </>
      </Playground>
    </Section>

    <Section title="Lisäosat">
      <Paragraph>
        Lomakekentän alkuun tai loppuun on mahdollista yhdistää lyhyt
        lisäteksti. Loppuun voi vaihtoehtoisesti myös yhdistää painikkeen.
      </Paragraph>
      <Playground
        format="html"
        WrapperComponent={FormLayoutWrapper}
      >
        <>
          <div className="form-group">
            <div className="input-group">
              <input
                className="form-control form-control-append"
                type="text"
              />
              <div className="input-group-append">
                <ButtonHTMLExample
                  className="btn-input-group"
                  icon={<IconHTMLExample name="SearchIcon" />}
                  variant="outline"
                >
                  Hae
                </ButtonHTMLExample>
              </div>
            </div>
          </div>
          <div className="form-group mb-0">
            <div className="input-group">
              <div className="input-group-prepend">
                <div
                  aria-label="Suomeksi"
                  className="input-group-text"
                  id="input-addon-html-example"
                >
                  FI
                </div>
              </div>
              <input
                aria-describedby="input-addon-html-example"
                className="form-control form-control-prepend"
                type="text"
              />
            </div>
          </div>
        </>
      </Playground>
    </Section>
    <Section title="Sähköpostiosoite">
      <Paragraph>
        Chrome käsittelee tiettyjä erikoismerkkejä (spesifikaation mukaan
        valideja merkkejä) eri tavalla kuin muut selaimet. Jos tämä aiheuttaa
        joissakin tilanteissa hankaluuksia, niin <Code>input type="email"</Code>{' '}
        sijaan voidaan käyttää inputmode-attribuuttia{' '}
        <Code>input type="text" inputmode="email"</Code>, joka huolehtii siitä,
        että mobiililla näkyy sopiva näppäimistö. Toki sähköpostiosoitteen
        validointi täytyy tehdä manuaalisesti.
      </Paragraph>
      <Paragraph>
        Lisätietoa:
        <br />
        <a href="https://bugs.chromium.org/p/chromium/issues/detail?id=410937">
          https://bugs.chromium.org/p/chromium/issues/detail?id=410937
        </a>
        <br />
        <a href="https://stackoverflow.com/questions/32117497/input-type-email-value-in-chrome-with-accented-characters-wrong">
          https://stackoverflow.com/questions/32117497/input-type-email-value-in-chrome-with-accented-characters-wrong
        </a>
      </Paragraph>
      <Playground
        example={InputEmailExample}
        WrapperComponent={FormLayoutWrapper}
      />
    </Section>
    <Section title="Sallitut merkit">
      <TextFieldCharacters />
    </Section>
    <Section title="Disabloitu">
      <Paragraph>
        Käytetään tilanteissa, joissa lomakkeella aiemmin tehdyt valinnat
        vaikuttavat dynaamisesti siihen voiko kenttään syöttää tietoa, mutta
        valinnan ei haluta piilottavan kenttää. Kentän disabloiva valinta
        sijoitetaan aina ennen disabloitavaa kenttää, mahdollisimman lähelle
        sitä.
      </Paragraph>

      <Playground WrapperComponent={FormLayoutWrapper}>
        <>
          <FormGroup>
            <Input
              disabled
              label="Label"
              value="Lorem ispum dolor sit amet"
            />
          </FormGroup>
          <FormGroup noMargin>
            <Input
              disabled
              label="Label"
              placeholder="Placeholder"
            />
          </FormGroup>
        </>
      </Playground>
      <Playground
        format="html"
        WrapperComponent={FormLayoutWrapper}
      >
        <>
          <div className="form-group">
            <LabelHTMLExample
              disabled
              htmlFor="input-html-value-disabled"
            >
              Label
            </LabelHTMLExample>
            <input
              className="form-control"
              disabled
              id="input-html-value-disabled"
              required
              type="text"
              value="Lorem ispum dolor sit amet"
            />
          </div>
          <div className="form-group mb-0">
            <LabelHTMLExample
              disabled
              htmlFor="input-html-placeholder-disabled"
            >
              Label
            </LabelHTMLExample>
            <input
              className="form-control"
              disabled
              id="input-html-placeholder-disabled"
              required
              placeholder="Placeholder"
              type="text"
            />
          </div>
        </>
      </Playground>
    </Section>
    <Section title="Lukittu">
      <Paragraph>
        <Strong>VANHENTUNUT, älä käytä!</Strong> Käytä lukitun inputin sijaan{' '}
        <Link page="Detail" />
        -komponenttia. Ruudunlukijalla luettuna lukittu kenttä kuulostaa ensin
        tavalliselta kentältä, vasta viimeisenä kentästä luetaan read-only
        -tieto. Koska sisältöä ei voi muokata, on turha kenttä tabilla
        navigoidessa.
      </Paragraph>
      <Playground WrapperComponent={FormLayoutWrapper}>
        <FormGroup noMargin>
          <Input
            label="Label"
            readOnly
            value="Lorem ispum dolor sit amet"
          />
        </FormGroup>
      </Playground>
    </Section>
    <Section title="Saavutettavuus">
      <Paragraph>
        Käyttämällä komponentin omia proppeja varmistetaan saavutettavuus. Jos
        käytetään esim. <Code>Label</Code> tai <Code>Required</Code>{' '}
        -komponentteja, tulee itse huolehtia saavutettavuudesta.
      </Paragraph>
      <Paragraph>
        Lomakekentän kielen määrittelystä löytyy esimerkki kohdassa{' '}
        <a href="#etuliite-kieliversiointi">Etuliite: kieliversiointi</a>.
      </Paragraph>
    </Section>
  </Content>
)

export default Page
