import React from 'react'

import {
  FormGroup,
  Input,
  InputGroupText,
  Label,
  Stack
} from '@te-digi/styleguide'

const LabelWithErrorExample = () => (
  <FormGroup ariaLabelledby="input-group-prepend-example-label-with-errormsg">
    <Label
      as="p"
      id="input-group-prepend-example-label-with-errormsg"
    >
      Kieliversioitu kenttä
    </Label>
    <Stack gap="md">
      <Input
        lang="fi"
        addonPrepend={<InputGroupText ariaLabel="Suomeksi">FI</InputGroupText>}
        error="Virheviesti suomeksi"
      />
      <Input
        lang="en"
        addonPrepend={
          <InputGroupText ariaLabel="In English">EN</InputGroupText>
        }
        error="Error message in English"
      />
    </Stack>
  </FormGroup>
)

export { LabelWithErrorExample }
